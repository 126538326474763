
import './Chip.css';
import _003ETH from './01BNB.png';
import _006ETH from './02BNB.png';
import _015ETH from './03BNB.png';
import _03ETH from './05BNB.png';
import _06ETH from './1BNB.png';
import { forwardRef } from 'react';

function Chip(props, ref) {

    const {amount} = props;

    function identifyChipImage() {
        if(amount >= 0.008) {
            return _06ETH;
        } else if (amount >= 0.004) {
            return _03ETH;
        } else if (amount >= 0.0016) {
            return _015ETH;
        } else if (amount >= 0.0008) {
            return _006ETH;
        } else {
            return _003ETH;
        }
    }

    return amount > 0 ? <div className='container'>    
    <div className='imgContainer'>
        <img src={identifyChipImage()} alt="Chip" className="chipImg"/>
    </div>
        <div className="centered">{amount}</div> 
    </div> : <div></div>
}

export default forwardRef(Chip);