import './App.css';
import './animate.css';
import background from './background.png';
import logo from './logo.png';
import wheel from './wheel.png';
import _003ETH from './01BNB.png';
import _006ETH from './02BNB.png';
import _015ETH from './03BNB.png';
import _03ETH from './05BNB.png';
import _06ETH from './1BNB.png';
import spin from './spin.png';
import Table from './Table';
import React, { useEffect, useState, useRef } from 'react';
// import { getWeb3 } from './utils.js';
import {useWeb3Modal} from '@web3modal/wagmi/react';
import {useAccount, useBalance, useWalletClient} from 'wagmi';
import { writeContract, watchContractEvent, waitForTransactionReceipt } from '@wagmi/core'
import { parseEther, formatEther } from 'viem';
import RouletteContract from './contracts/Roulette.json';
import { config } from './web3modal.js';
import gsap from "gsap";
import { useGSAP } from '@gsap/react';

function App() {
  const [betText, setBetText] = useState('0');
  const [previousTotalBet, setPreviousTotalBet] = useState(0);
  const [selectedChip, setChip] = useState();
  const [spinResult, setSpinResult] = useState();
  const [bets, setBets] = useState([]);
  const [betInFull, setBetInFull] = useState();
  const [chips, setChips] = useState([_003ETH, _006ETH, _015ETH, _03ETH, _06ETH]) 
  const table = useRef();
  const { address, chainId, status, connector, isDisconnected } = useAccount()
  const { open } = useWeb3Modal()
  const [isClosableOnClick, setClosableOnClick] = useState(true);
  const [canUndo, setCanUndo] = useState(false);
  const {data: balance, refetch, error} = useBalance({address: address});
  const [winningNumbers, setWinningNumbers] = useState([]);

  const wheelRef = useRef();
  const ballRef = useRef();
  const timelineRef = useRef();
  

  //const ROULETTE_ADDRESS = "0xa135fcF4ad8CF6b4cf6d512E9694ccA14159eA3D"; // testnet
  //const HOUSE_ADDRESS = "0x9F5fbB42Bb655A3d118F85A6Db22ddD12FA07D3d";
  //const ROULETTE_ADDRESS = "0x6F8D59C326bDDcBcA4CcbE2dB61D724c187E0Bec"; //testnet 13/05/24

  // const ROULETTE_ADDRESS = "0xe9d86fdFB0586d34A983d279fbB69df11F700e7e"; // live
   const ROULETTE_ADDRESS = "0xf3D77E4233bf7c5984A411b19131621cA63e2c53"; // live 13/05/24
  //const HOUSE_ADDRESS = "0xE30d2846514A667ADd9c19cfE604De008e0abBA6";

  // const requiredChainID = 81457; // blast mainnet
  // const requiredChainID = 168587773;

  useGSAP(
    () => {
        // ✅ safe, created during execution, selector text scoped
        gsap.to(wheelRef.current, { rotation: 360, duration: 10, repeat: -1, ease: "none"});
    },
    { scope: wheelRef }
  );

  useGSAP(
    () => {
      timelineRef.current = gsap.timeline({paused: true});
    },
    {scope: ballRef}
  );

  useEffect(() => {
    var dropdownOpen = false;
    var hamburger = document.getElementById("open-mobile-menu");
  
    if (hamburger.addEventListener) {
      hamburger.addEventListener("click", function() {
        dropdownOpen = true;
  
        document.getElementById('dropdown').style.width = "100%";

        //document.getElementById('dropdown-close').className += 'animated fadeIn';
        document.getElementById('dropdown-close').style.display = 'block';
  
        document.getElementById('mobile-navLinks').className += 'animated fadeIn';
        document.getElementById('mobile-navLinks').style.display = 'block';
      }, false);
    }
  
    var el = document.getElementById("dropdown-close");
  
    if (el.addEventListener) {
      el.addEventListener("click", function() {
        document.getElementById('dropdown-close').style.display = 'none';
        document.getElementById('mobile-navLinks').style.display = 'none';
        dropdownOpen = false;
        document.getElementById('dropdown').style.width = "0px";
      }, false);
    }

    document.getElementById("popup-wrapper").addEventListener("click", ( e ) => {
      e = window.event || e; 
      console.log(isClosableOnClick);
      if(this === e.target && isClosableOnClick) {
        document.getElementById('popup-wrapper').style.display = 'none';
        document.getElementById('popup-info').style.visibility = 'hidden';
        document.getElementById('popup-howto').style.visibility = 'hidden';
      }
    });

    if(window.ethereum) {
      init();
      
      window.ethereum.on('accountsChanged', accounts => {
        if (accounts.length) {
          console.log("switched account in metamask");
          init();
        } else {
          console.log("logged out of metamask");
          // setAccounts([]);
        }
      });
  
      window.ethereum.on('chainChanged', function(networkId) {
        init();
      });
    }
  }, []);

  const delay = ms => new Promise(res => setTimeout(res, ms));

  async function init() {

    // const web3 = await getWeb3();

    // let network = await web3.eth.net.getId();

    // const accounts = await web3.eth.getAccounts();
    // setAccounts(accounts);
    // setAccountString(accounts[0].toString().substring(0, 8) + "...");

    // let contract = new web3.eth.Contract(RouletteContract.abi, ROULETTE_ADDRESS);
    // setContract(contract);

    // let balance = 0;

    setChips([_003ETH, _006ETH, _015ETH, _03ETH, _06ETH]);
    // console.log(balance);
    // console.log(status);

    // balance = await web3.eth.getBalance(accounts[0]);
    // balance = web3.utils.fromWei(balance.toString(), 'ether');

    // setBalance(balance);

    // reset all the app and UI elements
    setBets([]);
    setBetText('0');
    clearAll();

    resetChips('chip-1');

    setChip(_003ETH);
  }


  async function undo() {
    table.current.undo();
  }

  function clearAll() {
    table.current.clearAll();
  }

  function repeatBet() {
    setBetInFull(previousTotalBet);
    setBetText(previousTotalBet.toString());
    table.current.repeatBet();
  }

  function savePreviousBet() {
    table.current.savePreviousBet();
  }

  function addWinningNumber(number) {
    let newwinningNumbers = [...winningNumbers];
    newwinningNumbers.push(number);
    setWinningNumbers(newwinningNumbers.slice(-3));
  }

  function selectChip(chip, element) {
    resetChips(element.target.classList[0]);
    setChip(chip);
  }

  async function handleRefresh () {
    try {
      await refetch();
    } catch (error) {
      console.error("Error refreshing balance:", error);
    }
  }

  function resetChips(name) {
    let elementsRemove = document.getElementsByClassName("activeChip");

    elementsRemove[2].classList.remove('activeChip');
    elementsRemove[1].classList.remove('activeChip');
    elementsRemove[0].classList.remove('activeChip');

    let elementsAdd = document.getElementsByClassName(name);

    elementsAdd[2].classList.add('activeChip');
    elementsAdd[1].classList.add('activeChip');
    elementsAdd[0].classList.add('activeChip');
  }

  function countDecimals(decimal) {
    var num = parseFloat(decimal); // First convert to number to check if whole

    if(Number.isInteger(num) === true) {
      return 0;
    }

    var text = num.toString(); // Convert back to string and check for "1e-8" numbers
    
    if(text.indexOf('e-') > -1) {
      var [base, trail] = text.split('e-');
      var deg = parseInt(trail, 10);
      return deg;
    } else {
      var index = text.indexOf(".");
      return text.length - index - 1; // Otherwise use simple string function to count
    }
  }

  function toFixed(num, fixed) {
    var re = new RegExp('^-?\\d+(?:\.\\d{0,' + (fixed || -1) + '})?');
    return num.toString().match(re)[0];
  }

  async function spinWheel() {
    // const web3 = await getWeb3();
    document.getElementById('ball-pivot').style.visibility = `hidden`;


    let betObjs = [];

    // how much eth is on each number?
    let finalWagers = [
      0, 0, 0, 0, 0, 0, 0, 0, 0, 0, // 0 - 9
      0, 0, 0, 0, 0, 0, 0, 0, 0, 0, // 10 - 19
      0, 0, 0, 0, 0, 0, 0, 0, 0, 0, // 20 - 29
      0, 0, 0, 0, 0, 0, 0, 0];

    setSpinResult();

    // 'bets' contains a list of numbers that the bet affects, and how much is being put on them
    // 'final wagers' (generated from above list) is a simplified list of how much (in eth) is on each number
    // 'betObjs' is above list, in object form, each object is the number and how much is on it (in wei), (is sent to contract)

    // break bets down into the 37 numbers
    for(let i = 0; i < bets.length; i++) {
      for(let j = 0; j < bets[i][0].length; j++) {
        finalWagers[bets[i][0][j]] = addDecimals(finalWagers[bets[i][0][j]], bets[i][1])
      }
    }

    // console.log(finalWagers);

    for(let i = 0; i < finalWagers.length; i++) {
      if(finalWagers[i] > 0) { // only include numbers that have bets on them
        if(countDecimals(finalWagers[i]) >= 12) {
          finalWagers[i] = toFixed(finalWagers[i], 18);
        }

        betObjs.push(
          {
            number: i,
            wager: parseEther((finalWagers[i].toString()))
          }
        );
      }
    }

    // console.log(finalWagers);
    // console.log(betObjs);

    if(betObjs.length > 0) {
      // console.log(betInFull);
      // console.log(balance);
      if(status == 'connected' && betInFull <= balance.formatted) { // can you afford to spin for this bet?

        // document.getElementsByClassName("wheel-image")[0].className += ' rotate';

        let abi = RouletteContract.abi;

        try {
          loadingPopup("Please finalize bet by approving transaction.");
          const commitHash = await writeContract(config, {
            abi,
            address: ROULETTE_ADDRESS,
            functionName: "commitToBet",
            args: [
              betObjs,
              parseEther(betInFull.toString())
            ],
            account: address,
            chainId: chainId,
            connector,
            value: parseEther(betInFull.toString()),
            
          });

          await handleRefresh();

          
          loadingPopup("Processing bet...");


          const transactionReceipt = await waitForTransactionReceipt(config, {
            hash: commitHash,
            confirmations: 5
          })

          while(transactionReceipt.status != 'success') {
            console.log("waiting for transaction");
          }

          loadingPopup("Reveal winning number by approving transaction. Funds won will be automatically sent to your wallet.");

          const spinHash = await writeContract(config, {
            abi,
            address: ROULETTE_ADDRESS,
            functionName: "revealBet",
            account: address,
            chainId: chainId,
            args: [
              betObjs,
            ],
            connector
          });

          loadingPopup("Processing results...");

          //RELEASE BALL AND ANIMATE
          document.getElementById('ball-pivot').style.visibility = `visible`;
          timelineRef.current.clear();
          timelineRef.current.to(ballRef.current, {rotation: "+=360", duration: 0.75, repeat: -1, ease: "none"});
          timelineRef.current.play();


          let results = {};

          const unwatch = watchContractEvent(config, {
            abi,
            address: ROULETTE_ADDRESS,
            eventName: 'PlayerBet',
            onLogs(logs) {
              logs.forEach(function(log) {
                console.log(log);
                if(log.transactionHash == spinHash) {
                  results = log.args;
                  let winnings = formatEther(results.winnings.toString());
                  //SET ANIMATION ANGLE
                  //ROTATE TO WINNING NUMBER;
                  timelineRef.current.pause();
                  let rotationToInitial = 360 - (parseFloat(gsap.getProperty(ballRef.current, 'rotation')) % 360);
                  console.log(rotationToInitial);
                  let numberAngle = rotationToInitial + 360 + determineAngle(parseInt(results.randomNumber));
                  timelineRef.current.clear();
                  timelineRef.current.to(ballRef.current, {rotation: "+=" + numberAngle, duration: 1});
                  timelineRef.current.play();

                  // const root = document.querySelector(':root');
                  // root.style.setProperty("--end-rotation", `${(360 + determineAngle(parseInt(results.randomNumber)))}deg`);
                  // document.getElementById('ball-pivot').style.animation = `ballRotation 1s ease-out forwards`;

                  //DELAY 
                  setTimeout(async () => {
                    await handleRefresh();
                    if(winnings > 0) {
                      winnings = toFixed(parseFloat(winnings), 6)+' ETH'; 
                      closePopup();;
                      popup("Winnner! Winner!<br></br>" + winnings);
                    } else {
                      closePopup();
                    }
            
                    if(parseInt(results.randomNumber) == 37) {
                      setSpinResult('00');
                      addWinningNumber('00');
                    } else {
                      setSpinResult(parseInt(results.randomNumber));
                      addWinningNumber(parseInt(results.randomNumber).toString());
                    }

                  }, 1000);
                  
          
                  // document.getElementsByClassName("wheel-image")[0].classList.remove('rotate');
                  unwatch();
                }
              })
              
            }
          })

       
      } catch (error) {
        console.log(error);
        // document.getElementsByClassName("wheel-image")[0].classList.remove('rotate');
        closePopup();
      }
        setPreviousTotalBet(betInFull);
        savePreviousBet();
        init();
      }
    }
  }

  function connectWallet() {
    if(status == 'connected') {
      popup("Wallet Already Connected.")
    } else {
      init();
    }
  }

  function popup(popupText) {
    setClosableOnClick(true);
    document.getElementById('popup-text_info').innerHTML = popupText;
    document.getElementById('popup-wrapper').style.display = 'block';
    document.getElementById('popup-info').style.visibility = 'visible';
    document.getElementById('popup-info').className += ' animated fadeIn';
  }

  function loadingPopup(loadingText) {
    setClosableOnClick(false);
    console.log(isClosableOnClick);
    document.getElementById('popup-loading_text').innerHTML = loadingText;
    document.getElementById('popup-wrapper').style.display = 'block';
    document.getElementById('popup-loading').style.visibility = 'visible';
    document.getElementById('popup-loading').className += ' animated fadeIn';
  }

  function howTo() {
    document.getElementById('popup-wrapper').style.display = 'block';
    document.getElementById('popup-howto').style.visibility = 'visible';
    document.getElementById('popup-howto').className += ' animated fadeIn';
  }

  function closePopup() {
    document.getElementById('popup-wrapper').style.display = 'none';
    document.getElementById('popup-howto').style.visibility = 'hidden';
    document.getElementById('popup-info').style.visibility = 'hidden';
    document.getElementById('popup-loading').style.visibility = 'hidden';
  }

  function addDecimals(num1, num2) {
    var tempNum1 = num1.toString();
    var tempNum2 = num2.toString();
    var length1 = (tempNum1.split('.')[1] || []).length;
    var length2 = (tempNum2.split('.')[1] || []).length;
    var maxLength = Math.max(length1, length2);
    var factor = Math.pow(10, maxLength);
    return (Math.round(num1 * factor) + Math.round(num2 * factor)) / factor;
  }

  function determineAngle(winningNumber) {
    const numberSequence  = [28, 9, 26, 30, 11, 7, 20, 32, 17, 5, 22, 34, 15, 3, 24, 36, 13, 1, 37, 27, 10, 25, 29, 12, 8, 19, 31, 18, 6, 21, 33, 16, 4, 23, 35, 14, 2, 0];
    const anglePerSlot = 360 / numberSequence.length;
    return anglePerSlot * numberSequence.indexOf(winningNumber);
  }

  function determineBackgroundColor(winningNumber) {
    if(winningNumber == null) {
      return "";
    }
    const numberSequence  = [28, 9, 26, 30, 11, 7, 20, 32, 17, 5, 22, 34, 15, 3, 24, 36, 13, 1, 37, 27, 10, 25, 29, 12, 8, 19, 31, 18, 6, 21, 33, 16, 4, 23, 35, 14, 2, 0];
    if(winningNumber == 0 || winningNumber == 37) {
      return "green-result";
    } else if(numberSequence.indexOf(winningNumber) % 2 == 1) {
      return "red-result";
    } else {
      return "black-result";
    }
  }

  function determineColor(winningNumber) {
    if(winningNumber == null) {
      return "";
    }
    const numberSequence  = [28, 9, 26, 30, 11, 7, 20, 32, 17, 5, 22, 34, 15, 3, 24, 36, 13, 1, 37, 27, 10, 25, 29, 12, 8, 19, 31, 18, 6, 21, 33, 16, 4, 23, 35, 14, 2, 0];
    if(winningNumber == "0" || winningNumber == "00") {
      return "green";
    } else if(numberSequence.indexOf(parseInt(winningNumber)) % 2 == 1) {
      return "red";
    } else {
      return "black";
    }
  }

  

  return (
    <div className="App">
      <div className="main-section">
        <img className="section-background" src={background}></img>
        <div id="dropdown">
          <div id="dropdown-content">
            <div id="mobile-navLinks">
              <div className="exit-section">
                <i id="dropdown-close" className="far fa-times-circle"></i>
              </div>
              <a><img className="mobile-nav-logo" src={logo}></img></a>
              {status == 'connected' ? (
                <div className="mobile-wallet-connected-wrapper">
                  <i className="fa mobile-fa-wallet fa-wallet"></i>
                  <div className="mobile-account">{address}</div>
                </div>
              ):
                <div className="mobile-wallet" onClick={open}>CONNECT WALLET</div>
              }
            </div>
          </div>
        </div>
        <div className="nav-section">
          <div className="navbar">
            <a><img className="nav-logo" src={logo}></img></a>
            <div className="nav-links-section">
              {status == 'connected' ? (
                <div className="wallet-connected-wrapper">
                  <i className="fas fa-wallet"></i>
                  <div className="account">{address}</div>
                </div>
              ):
                <div className="wallet" onClick={open}>CONNECT WALLET</div>
              }
            </div>
            <div id="open-mobile-menu" className="hamburger">
              <span id="top"></span>
              <span id="middle"></span>
              <span id="bottom"></span>
            </div>
          </div>
        </div>


        <div id="popup-wrapper">
          <div className="popup" id="popup-info">
            <div id="popup-text_info"></div>
            <div className="popup-button" onClick={closePopup}>OK</div>
          </div>

          <div className="popup" id="popup-loading">
            <div id="popup-loading_text" className="loading-text"></div>
          </div>

          <div className="popup" id="popup-howto">
            <div className="popup-text">Maximum bet is $10 per number. Maximum payout is $360.
			Select the value of chip you want and place it on the roulette table.
When you are happy with the bets you have placed, select spin.
If this is your 1st spin of roulette, you will need to approve the smart contract to take your bet.
Your Wallet should now ask you to confirm your bet.
The smart contract will receive your bets, generate a random number, send that random number to the roulette game to display.
If you have won the funds will be sent directly to your wallet.</div>
            <div className="popup-button" onClick={closePopup}>OK</div>
          </div>
        </div>


        <div className="section-wrapper">
          <div className="howto-button" onClick={howTo}>
            <div className="howto-button-text">?</div>
          </div>


          <div className="wheel-wrapper">
            <div ref={wheelRef} className="wheel-pivot">

              <div ref={ballRef} id="ball-pivot">
                <div id="ball"></div>
              </div>
              <img className="wheel-image" src={wheel}></img>
            </div>
            {spinResult != null ? (
              <div className={`result ${determineBackgroundColor(spinResult)}`}>
                <div className="result-text">{spinResult}</div>
              </div>
            ):
              null
            }
          </div>
          <div className="bet-wrapper">
            <div className="bet-info">
            <div className="winning-section bet-left">
                <div className="winning-header">PREVIOUS:</div>
                <div className="winning-numbers">{winningNumbers.map((element) => <div className={`winning-number ${determineColor(element)}`}>{element}</div>)}</div>
                <div className="bet-text"></div>
              </div>
              <div className="bet bet-left">
                <div className="bet-text">BALANCE</div>
                <div className="bet-result">{balance ? toFixed(parseFloat(balance.formatted), 5) : 0}</div>
                <div className="bet-text">ETH</div>
              </div>
              <div className="bet">
                <div className="bet-text">BET</div>
                <div className="bet-result">{betText}</div>
                <div className="bet-text">ETH</div>
              </div>
            </div>
          </div>
          <div className="medium-chips">
            <div className="sm-flex">
              <div className="chip-container sm-margin">
                <img className="chip-1 activeChip" id="medium-chip-image" src={chips[0]} onClick={element => selectChip(chips[0], element)}></img>
                <div className="chip-centered med-text">0.0004</div>
              </div>
              <div className="chip-container sm-margin">
                <img className="chip-2" id="medium-chip-image" src={chips[1]} onClick={element => selectChip(chips[1], element)}></img>
                <div className="chip-centered med-text">0.0008</div>
              </div>
              <div className="chip-container sm-margin">
                <img className="chip-3" id="medium-chip-image" src={chips[2]} onClick={element => selectChip(chips[2], element)}></img>
                <div className="chip-centered med-text">0.0016</div>
              </div>

            </div> 
            <div className="sm-flex">
              <div className="chip-container sm-margin">
               
                <img className="chip-4" id="medium-chip-image" src={chips[3]} onClick={element => selectChip(chips[3], element)}></img>
                <div className="chip-centered med-text">0.0016</div>
              </div>
              <div className="chip-container sm-margin">
               <img className="chip-5" id="medium-chip-image" src={chips[4]} onClick={element => selectChip(chips[4], element)}></img>
               <div className="chip-centered med-text">0.0016</div>
              </div>
            </div>
          </div>
          <div className="table-section">
            <Table ref={table} setBetInFull={setBetInFull} setBetText={setBetText} setBets={setBets} bets={bets} selectedChip={selectedChip} setCanUndo={setCanUndo}></Table>
          </div>
          <div className="small-chips">
            <div className="sm-flex">
              <div className="chip-container sm-margin">
                <img className="chip-1 activeChip" id="small-chip-image" src={chips[0]} onClick={element => selectChip(chips[0], element)}></img>
                <div className="chip-centered small-text">0.0004</div>
              </div>
              <div className="chip-container sm-margin">
                <img className="chip-2" id="small-chip-image" src={chips[1]} onClick={element => selectChip(chips[1], element)}></img>
                <div className="chip-centered small-text">0.0008</div>
              </div>
              <div className="chip-container sm-margin">
                <img className="chip-3" id="small-chip-image" src={chips[2]} onClick={element => selectChip(chips[2], element)}></img>
                <div className="chip-centered small-text">0.0016</div>
              </div>
            </div>
            <div className="sm-flex">
              <div className="chip-container sm-margin">
                <img className="chip-4" id="small-chip-image" src={chips[3]} onClick={element => selectChip(chips[3], element)}></img>
                <div className="chip-centered small-text">0.004</div>
              </div>
              <div className="chip-container sm-margin">
                <img className="chip-5" id="small-chip-image" src={chips[4]} onClick={element => selectChip(chips[4], element)}></img>
                <div className="chip-centered small-text">0.008</div>
              </div>
            </div>
          </div>
          <div className="controls-section">
            <div className="spin-button">
              <div className="spin" onClick={bets.length == 0 && previousTotalBet > 0 ? repeatBet : spinWheel}>
                <img className="spin-image" src={spin}></img>
              </div>
              <div className="control-button" onClick={bets.length == 0 && previousTotalBet > 0 ? repeatBet : spinWheel}>{bets.length == 0 && previousTotalBet > 0? "Repeat Bet" : "Spin"}</div>
            </div>
            <div className={canUndo ?"control-button" : "disabled-control-button"} onClick={undo} >Undo</div>
            <div className="control-button" onClick={clearAll}>Clear All</div>
          </div>
          <div className="chips-section">
            <div className="chip-container chip-margin">
              <img className="chip-1 activeChip" id="large-chip-image" src={chips[0]} onClick={element => selectChip(chips[0], element)}></img>
              <div className="chip-centered large-text">0.0004</div>
            </div>
            <div className="chip-container chip-margin">
              <img className="chip-2" id="large-chip-image" src={chips[1]} onClick={element => selectChip(chips[1], element)}></img>
              <div className="chip-centered large-text">0.0008</div>
            </div>
            <div className="chip-container chip-margin">
              <img className="chip-3" id="large-chip-image" src={chips[2]} onClick={element => selectChip(chips[2], element)}></img>
              <div className="chip-centered large-text">0.0016</div>
            </div>
            <div className="chip-container chip-margin">
              <img className="chip-4" id="large-chip-image" src={chips[3]} onClick={element => selectChip(chips[3], element)}></img>
              <div className="chip-centered large-text">0.004</div>
            </div>
            <div className="chip-container chip-margin">
              <img className="chip-5" id="large-chip-image" src={chips[4]} onClick={element => selectChip(chips[4], element)}></img>
              <div className="chip-centered large-text">0.008</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
