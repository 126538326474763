import React from 'react';
import {createRoot} from 'react-dom/client';
import App from './App';
import Web3modal from './web3modal.js';
import { useGSAP } from '@gsap/react';
import gsap from "gsap";

const container = document.getElementById('root');
const root = createRoot(container);
gsap.registerPlugin(useGSAP);

root.render(<React.StrictMode>
    <Web3modal>
  <App />
  </Web3modal>
</React.StrictMode>);
